/*
 * @Description: 文件描述
 */

// 主要数据类型
const DETECTED_DATA_TYPE_ENUM = {
  'VISIBLE_LIGHT': '可见光图像',
  'THERMAL_IMAGE': '热成像图像',
  'KNOCK_IMAGE': '敲击数据',
  'RADAR_IMAGE': '雷达数据',
  values: ['VISIBLE_LIGHT', 'THERMAL_IMAGE', 'KNOCK_IMAGE', 'RADAR_IMAGE']
}

export {
  DETECTED_DATA_TYPE_ENUM
}
