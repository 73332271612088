<!--
 * @Description: 文件描述
-->
<template>
  <div class="base_info">
    <h2 class="report_title">
      <slot></slot>
    </h2>
    <a-card>
      <span slot="title">项目名称：{{ info.projectName }}</span>
    </a-card>
    <div class="mt-10"></div>
    <a-card title="项目描述：">
      <p>{{ info.description }}</p>
    </a-card>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    info: {
      type: Object,
      default: () => ({
        title: '',
        projectName: '',
        description: ''
      })
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.report_title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
</style>
