/*
 * @Description: 文件描述
 */
import Vue from 'vue'

const bus = new Vue()

function flatternData(arr) {
  const result = []
  const _func = (list, parentKey = '') => {
    list.forEach((item) => {
      const { id, name, red, type } = item
      const key = `${id}-${type}`
      result.push({ parentKey, id, name, red, type, key: `${item.id}-${item.type}`, title: name })
      if (item.children?.length) {
        _func(item.children, key)
      }
    })
  }
  _func(arr)

  return result
}

export {
  flatternData,
  bus
}
