var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-card", { staticClass: "mark_item_wrapper" }, [
    _c(
      "div",
      { staticClass: "card_title", attrs: { slot: "title" }, slot: "title" },
      [
        _c("b", { staticClass: "name" }, [
          _vm._v(
            "标记" +
              _vm._s(_vm.index + 1) +
              "(" +
              _vm._s(_vm.DETECTED_DATA_TYPE_ENUM[_vm.marker.dataType]) +
              ")"
          ),
        ]),
        _c("i", { staticClass: "coordinates" }, [
          _vm._v(
            "中心坐标: X: " +
              _vm._s(_vm.formateMarkVal(_vm.markExtentCenter[0])) +
              "mm Y: " +
              _vm._s(_vm.formateMarkVal(_vm.markExtentCenter[1])) +
              "mm"
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "main_data_content" }, [
      _c("div", { staticClass: "left_img content_item" }, [
        _c("b", { staticClass: "content_item_title" }, [_vm._v("区域图像：")]),
        _c("img", {
          staticClass: "img_item",
          attrs: { src: _vm.marker.imgUrl, alt: "" },
          on: { click: _vm.previewImage },
        }),
      ]),
      _c(
        "div",
        { staticClass: "center_coordinates_content content_item" },
        [
          _vm.isLineMaker
            ? [
                _c("b", { staticClass: "content_item_title" }, [
                  _vm._v("长度标记："),
                ]),
                _c("span", { staticClass: "item_title" }, [_vm._v("起点坐标")]),
                _c("span", [
                  _vm._v(
                    "X: " +
                      _vm._s(_vm.formateMarkVal(_vm.linemMarkerStartPoint.x)) +
                      "mm Y: " +
                      _vm._s(_vm.formateMarkVal(_vm.linemMarkerStartPoint.y)) +
                      "mm"
                  ),
                ]),
                _c("span", { staticClass: "item_title" }, [_vm._v("终点坐标")]),
                _c("span", [
                  _vm._v(
                    "X: " +
                      _vm._s(_vm.formateMarkVal(_vm.linemMarkerEndPoint.x)) +
                      "mm Y: " +
                      _vm._s(_vm.formateMarkVal(_vm.linemMarkerEndPoint.y)) +
                      "mm"
                  ),
                ]),
                _c("span", { staticClass: "item_title" }, [
                  _vm._v(
                    "长度: " +
                      _vm._s(_vm.formateMarkVal(_vm.marker.value)) +
                      "mm"
                  ),
                ]),
              ]
            : [
                _c("b", { staticClass: "content_item_title" }, [
                  _vm._v("面积标记："),
                ]),
                _c("span", { staticClass: "item_title" }, [_vm._v("中心坐标")]),
                _c("span", [
                  _vm._v(
                    "X: " +
                      _vm._s(_vm.formateMarkVal(_vm.marker.markerCentreX)) +
                      "mm Y: " +
                      _vm._s(_vm.formateMarkVal(_vm.marker.markerCentreY)) +
                      "mm"
                  ),
                ]),
                _c("span", { staticClass: "item_title" }, [
                  _vm._v(
                    "面积: " +
                      _vm._s(_vm.formateMarkVal(_vm.marker.value, 2)) +
                      "mm² ≈ " +
                      _vm._s(
                        _vm.getAreaByMeter(
                          _vm.formateMarkVal(_vm.marker.value, 2)
                        )
                      ) +
                      "m²"
                  ),
                ]),
              ],
        ],
        2
      ),
      _c("div", { staticClass: "right_desc content_item" }, [
        _c("b", { staticClass: "content_item_title" }, [_vm._v("描述：")]),
        _c("p", [_vm._v(_vm._s(_vm.marker.description))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }