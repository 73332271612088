<!--
 * @Description: 文件描述
-->
<template>
  <div class="wall_content_wrapper">
    <div class="data_filter_tags mb-10">
      <span>标记数据类型：</span>
      <ButtonSelector v-model="selectedTypes" :options="options"></ButtonSelector>
    </div>

    <MarkerCard
      v-for="(marker, index) in shownMarkerList"
      :key="marker.id"
      :marker="marker"
      :index="index"
    ></MarkerCard>

    <p v-if="!shownMarkerList.length">暂无标记</p>
  </div>
</template>

<script>
import MarkerCard from './MarkerCard.vue'
import ButtonSelector from './ButtonSelector.vue'

const DEFAULT_TYPE_LIST = ['VISIBLE_LIGHT', 'THERMAL_IMAGE', 'KNOCK_IMAGE', 'RADAR_IMAGE']

export default {
  name: 'WallContent',
  components: {
    ButtonSelector,
    MarkerCard
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    markerList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedTypes: DEFAULT_TYPE_LIST
    }
  },
  computed: {
    shownMarkerList() {
      return this.markerList.filter((marker) => {
        return this.selectedTypes.includes(marker.dataType)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
