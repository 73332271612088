<template>
  <page-header-wrapper class="view_report_wrapper">
    <a-spin :spinning="loading">
      <a-card class="content_containner">
        <BaseInfo :info="baseInfo">{{ baseInfo.title }}</BaseInfo>

        <div class="mt-10"></div>
        <!-- 主要内容区域 -->
        <a-card class="main_content_card">
          <div class="main_content" id="report_main_content" style="position: relative;">
            <TreeArea
              class="tree_comp"
              :treeData="baseInfo.tree"
              v-if="baseInfo.tree"
              :selectedKeys.sync="selectedKeys"
              @select="handleSelected"
            ></TreeArea>

            <BuidingReportInfo
              class="right_content"
              :editable="false"
              :reportId="baseInfo.id"
              :currentNode="currentNode"
              :buildingId="currentBuildingId"
              :buildingScore="currentBuildingScore"
            ></BuidingReportInfo>
          </div>
        </a-card>
        <a-card title="专家意见:">
          <p style="white-space: pre-line;">{{ baseInfo.opinion }}</p>
        </a-card>

        <div class="footer_btns mt-10">
          <a-button @click="$router.go(-1)">返回</a-button>
          <ExportBtn site="organization"></ExportBtn>
        </div>
      </a-card>
    </a-spin>
  </page-header-wrapper>
</template>

<script>
import BaseInfo from './components/BaseInfo.vue'
import TreeArea from './components/TreeArea.vue'
import BuidingReportInfo from './components/BuidingReportInfo.vue'
import ExportBtn from './components/ExportBtn.vue'
import ApiController from '../api/functionApi'

export default {
  name: 'ViewReport',
  components: {
    BaseInfo,
    TreeArea,
    BuidingReportInfo,
    ExportBtn
  },
  data() {
    return {
      loading: true,
      baseInfo: {},
      selectedKeys: [], // 当前选中的墙面
      currentNode: {},
      opinion: '', // 专家意见
      buildingScoreList: [] // 报告打分
    }
  },
  computed: {
    // 当前楼栋id
    currentBuildingId() {
      const { type, id, parentKey = '' } = this.currentNode
      if (type === 'BUILDING') {
        return id
      } else {
        return Number(parentKey.split('-')[0])
      }
    },
    // 当前楼栋评分
    currentBuildingScore() {
      return this.buildingScoreList.find((item) => item.buildingId === this.currentBuildingId)?.score
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true
        const params = { id: this.$route.params.id }
        this.baseInfo = await ApiController.getReprtBaseInfo(params)
        this._setDefaultSelected()
        this._initBuildingScoreList()
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    // 初始化默认选中
    _setDefaultSelected() {
      const firstBuilding = this.baseInfo.tree?.children[0]
      this.selectedKeys = [`${firstBuilding.id}-${firstBuilding.type}`]
      this.currentNode = firstBuilding
    },
    // 初始化楼栋评分
    _initBuildingScoreList() {
      this.buildingScoreList = this.baseInfo.tree?.children.map((item) => {
        return {
          name: item.name,
          buildingId: item.id,
          score: item.score
        }
      })
    },
    // 选择事件
    handleSelected(selectedKeys, { node }) {
      this.$nextTick(() => {
        this.selectedKeys = [node.dataRef.key] // 不允许取消选择
        this.currentNode = node.dataRef
      })
    },
    getReportParams() {
      return {
        id: this.baseInfo.id,
        title: this.baseInfo.title,
        opinion: this.opinion,
        buildingScoreList: this.buildingScoreList
      }
    },
    handleExport() {}
  }
}
</script>

<style lang="scss" scoped>
/* 标题输入框样式 */
.title_input {
  text-align: center;
  font-size: 30px;
  font-weight: bolder;
  padding: 30px 0;
  max-width: 24em;
}

.content_containner {
  ::v-deep .ant-card {
    border-radius: 2px !important;
  }
  ::v-deep .ant-card-head-title {
    font-weight: bold;
  }

  .main_content_card {
    ::v-deep .ant-card-body {
      padding: 5px;
    }
  }

  // 主要内容部分
  .main_content {
    display: flex;

    .tree_comp {
      max-width: 1000px;
      overflow: auto;
      min-width: 240px;
      padding: 5px;
      margin-right: 5px;
      max-height: 1000px;
      overflow: auto;
      border: 1px solid #eee;
    }

    /* 右侧主内容区域渲染 */
    .right_content {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      max-height: 1000px;
      overflow: hidden;
      ::v-deep > .ant-card-head {
        margin-bottom: 5px;
      }
      ::v-deep > .ant-card-body {
        flex: 1;
        overflow: auto;
      }
    }
  }

  /* 底部按钮 */
  .footer_btns {
    text-align: right;
    ::v-deep .ant-btn {
      margin-left: 10px;
    }
  }
}
</style>
