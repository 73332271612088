<!--
 * @Description: 文件描述
-->
<template>
  <a-dropdown>
    <a-menu slot="overlay" @click="handleMenuClick">
      <a-menu-item key="pdf">导出报告</a-menu-item>
      <a-menu-item key="img">导出标记</a-menu-item>
    </a-menu>

    <a-button type="primary" :loading="loading">
      导出
      <a-icon type="down" />
    </a-button>
  </a-dropdown>
</template>

<script>
import { downloadFile } from '@/utils/fileDownload'
import axios from 'axios'

export default {
  name: 'ExportBtn',
  props: {
    // 站点类型,后台，专家，检测机构
    site: {
      type: String,
      default: 'backend'
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async handleMenuClick(e) {
      const downloadURL = e.key === 'img' ? this.getImgDownloadURL() : this.getPDFDownloadURL()
      try {
        this.loading = true
        const response = await axios({
          url: downloadURL,
          method: 'get',
          responseType: 'blob'
        })
        downloadFile(response)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    getImgDownloadURL() {
      return `/foundation/api/${this.site}/workOrder/${this.$route.params.id}/report/marker/export`
    },
    getPDFDownloadURL() {
      return `/foundation/api/${this.site}/workOrder/${this.$route.params.id}/report/export`
    }
  }
}
</script>

<style lang="scss" scoped></style>
