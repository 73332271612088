var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    { staticClass: "view_report_wrapper" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c(
            "a-card",
            { staticClass: "content_containner" },
            [
              _c("BaseInfo", { attrs: { info: _vm.baseInfo } }, [
                _vm._v(_vm._s(_vm.baseInfo.title)),
              ]),
              _c("div", { staticClass: "mt-10" }),
              _c("a-card", { staticClass: "main_content_card" }, [
                _c(
                  "div",
                  {
                    staticClass: "main_content",
                    staticStyle: { position: "relative" },
                    attrs: { id: "report_main_content" },
                  },
                  [
                    _vm.baseInfo.tree
                      ? _c("TreeArea", {
                          staticClass: "tree_comp",
                          attrs: {
                            treeData: _vm.baseInfo.tree,
                            selectedKeys: _vm.selectedKeys,
                          },
                          on: {
                            "update:selectedKeys": function ($event) {
                              _vm.selectedKeys = $event
                            },
                            "update:selected-keys": function ($event) {
                              _vm.selectedKeys = $event
                            },
                            select: _vm.handleSelected,
                          },
                        })
                      : _vm._e(),
                    _c("BuidingReportInfo", {
                      staticClass: "right_content",
                      attrs: {
                        editable: false,
                        reportId: _vm.baseInfo.id,
                        currentNode: _vm.currentNode,
                        buildingId: _vm.currentBuildingId,
                        buildingScore: _vm.currentBuildingScore,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("a-card", { attrs: { title: "专家意见:" } }, [
                _c("p", { staticStyle: { "white-space": "pre-line" } }, [
                  _vm._v(_vm._s(_vm.baseInfo.opinion)),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "footer_btns mt-10" },
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                    },
                    [_vm._v("返回")]
                  ),
                  _c("ExportBtn", { attrs: { site: "organization" } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }