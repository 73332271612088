<!--
 * @Description: 文件描述
-->
<template>
  <div class="button_select_wrapper">
    <a-tag
      v-for="(option, index) in options"
      :key="index"
      :class="optionClass(option)"
      :color="getType(option)"
      @click="() => handleSelectedChange(option.value)"
    >{{ option.label }}</a-tag>
  </div>
</template>

<script>
export default {
  name: 'ButtonSelector',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  methods: {
    optionClass(option) {
      return this.value.includes(option.value) ? 'tag_btn' : 'tag_btn not_selected'
    },
    getType(option) {
      return this.value.includes(option.value) ? 'blue' : ''
    },
    handleSelectedChange(value) {
      let newValue = [...this.value]
      const hasItem = newValue.find((item) => item === value)
      if (hasItem) {
        newValue = newValue.filter((item) => item !== value)
      } else {
        newValue.push(value)
      }

      this.$emit('input', newValue)
      this.$emit('change', newValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.button_select_wrapper {
  display: inline-block;
}

.button_select_wrapper {
  .tag_btn {
    cursor: pointer;
    font-size: 14px;
    padding: 4px 12px;
  }
}
</style>
