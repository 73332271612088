/*
 * @Description: 文件描述
 */

import { requestCustom as request } from '@/utils/request'

const BASE_URL = `/api/organization/orderDetail`

// 报告工单管理-项目详情 左侧列表
function orderDetailTree(params) {
  return request({
    url: `${BASE_URL}/tree/${params.id}`,
    method: 'get',
  })
}

// 项目详情-最大楼栋数量
function getBuildingMaxNumber(params) {
  return request({
    url: `${BASE_URL}/building/max/${params.id}`,
    method: 'get',
  })
}

// 项目详情-最大检测点数量
function getPointMaxNumber(params) {
  return request({
    url: `${BASE_URL}/point/max/${params.id}`,
    method: 'get',
  })
}

// 报告工单管理-请求分析
function submitAnalysisApplication(params) {
  return request({
    url: `/api/organization/workOrder/analyse/${params.id}`,
    method: 'post',
    data: params
  })
}

// 报告工单管理-驳回原因
function rejectReason(params) {
  return request({
    url: `/api/organization/workOrder/rejectReason/${params.id}`,
    method: 'get',
  })
}

// 报告工单管理-查看报告
function getReprtBaseInfo(params) {
  return request({
    url: `/api/organization/workOrder/report/${params.id}`,
    method: 'get',
    params
  })
}

// 报告工单管理-查看报告_右侧正文
function getReportBuildingInfo(params) {
  return request({
    url: `/api/organization/workOrder/report/${params.reportId}/building/${params.buildingId}`,
    method: 'get',
    params
  })
}

// 报告工单管理-分析详情
function viewReportDetail(params) {
  return request({
    url: `/api/organization/workOrder/detail/${params.id}`,
    method: 'get',
    params
  })
}

export {
  orderDetailTree,
  getBuildingMaxNumber,
  getPointMaxNumber,
  submitAnalysisApplication,
  rejectReason,
  getReprtBaseInfo,
  getReportBuildingInfo,
  viewReportDetail
}

export default {
  orderDetailTree,
  getBuildingMaxNumber,
  rejectReason,
  getReprtBaseInfo,
  getReportBuildingInfo
}
