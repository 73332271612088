<!--
 * @Description: 文件描述
-->
<template>
  <div class="wall_btn_group">
    <span>标记大图查看：</span>
    <a-tag
      color="#003cff"
      v-for="item in options"
      :key="item.value"
      @click="(e) => handleShowImg(e, item.value)"
    >{{ item.label }}</a-tag>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    options: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleShowImg(e, type) {
      e.stopPropagation()
      this.$emit('showImg', type)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
