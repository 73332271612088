var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wall_content_wrapper" },
    [
      _c(
        "div",
        { staticClass: "data_filter_tags mb-10" },
        [
          _c("span", [_vm._v("标记数据类型：")]),
          _c("ButtonSelector", {
            attrs: { options: _vm.options },
            model: {
              value: _vm.selectedTypes,
              callback: function ($$v) {
                _vm.selectedTypes = $$v
              },
              expression: "selectedTypes",
            },
          }),
        ],
        1
      ),
      _vm._l(_vm.shownMarkerList, function (marker, index) {
        return _c("MarkerCard", {
          key: marker.id,
          attrs: { marker: marker, index: index },
        })
      }),
      !_vm.shownMarkerList.length ? _c("p", [_vm._v("暂无标记")]) : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }