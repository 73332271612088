import download from 'downloadjs'

function extractFileName(contentDispositionHeader) {
  // 有的导出接口返回的文件名有引号包裹
  const regexp = /filename="([^\s^"]+)"[;\s]*/

  // 这个正则表达式更加general，所以匹配优先级靠后
  const regexp1 = /filename=([^\s]+)[;\s]*/

  if (regexp.test(contentDispositionHeader)) {
    return decodeURI(regexp.exec(contentDispositionHeader)[1])
  }

  if (regexp1.test(contentDispositionHeader)) {
    return decodeURI(regexp1.exec(contentDispositionHeader)[1])
  }

  console.error(`Unable to extract filename from '${contentDispositionHeader}'`)
  return null
}

function downloadFile(axiosResponse, mimeType, fileName) {
  const contentTypeHeader = axiosResponse.headers['content-type']

  if (mimeType == null && contentTypeHeader !== null && contentTypeHeader !== '') {
    mimeType = contentTypeHeader.split(';')[0]
  }

  if (fileName == null) {
    fileName = extractFileName(axiosResponse.headers['content-disposition'])
  }

  download(axiosResponse.data, fileName, mimeType)
}

function dowloadFileWithLink(FileUrl) {
  const a = document.createElement('a')
  a.href = FileUrl
  a.click()
  a.remove()
}

export { extractFileName, downloadFile, dowloadFileWithLink }
