<!--
 * @Description: 标记
-->
<template>
  <a-card class="mark_item_wrapper">
    <!-- 标题 -->
    <div class="card_title" slot="title">
      <b class="name">标记{{ index + 1 }}({{ DETECTED_DATA_TYPE_ENUM[marker.dataType] }})</b>
      <i
        class="coordinates"
      >中心坐标: X: {{ formateMarkVal(markExtentCenter[0]) }}mm Y: {{ formateMarkVal(markExtentCenter[1]) }}mm</i>
    </div>

    <!-- 主内容区域 -->
    <div class="main_data_content">
      <!-- 左侧图像 -->
      <div class="left_img content_item">
        <b class="content_item_title">区域图像：</b>
        <img :src="marker.imgUrl" alt class="img_item" @click="previewImage" />
      </div>

      <!-- 中间数据 -->
      <div class="center_coordinates_content content_item">
        <!-- 长度标记 -->
        <template v-if="isLineMaker">
          <b class="content_item_title">长度标记：</b>
          <span class="item_title">起点坐标</span>
          <span>X: {{ formateMarkVal(linemMarkerStartPoint.x) }}mm Y: {{ formateMarkVal(linemMarkerStartPoint.y) }}mm</span>
          <span class="item_title">终点坐标</span>
          <span>X: {{ formateMarkVal(linemMarkerEndPoint.x) }}mm Y: {{ formateMarkVal(linemMarkerEndPoint.y) }}mm</span>
          <span class="item_title">长度: {{ formateMarkVal(marker.value) }}mm</span>
        </template>
        <!-- 面积标记 -->
        <template v-else>
          <b class="content_item_title">面积标记：</b>
          <span class="item_title">中心坐标</span>
          <span>X: {{ formateMarkVal(marker.markerCentreX) }}mm Y: {{ formateMarkVal(marker.markerCentreY) }}mm</span>
          <span
            class="item_title"
          >面积: {{ formateMarkVal(marker.value, 2) }}mm² ≈ {{ getAreaByMeter(formateMarkVal(marker.value, 2)) }}m²</span>
        </template>
      </div>

      <!-- 右侧描述 -->
      <div class="right_desc content_item">
        <b class="content_item_title">描述：</b>
        <p>{{ marker.description }}</p>
      </div>
    </div>
  </a-card>
</template>

<script>
import { getCenter } from 'ol/extent'
import { DETECTED_DATA_TYPE_ENUM } from '@/const/DetectedDataTypeEnum'

export default {
  name: 'MarkItem',
  props: {
    // 标记本身
    marker: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loading: false,
      DETECTED_DATA_TYPE_ENUM
    }
  },
  computed: {
    // 标记的范围，后端取了四个字段
    markExtent() {
      const { rangeStartX, rangeStartY, rangeEndX, rangeEndY } = this.marker
      return [rangeStartX, rangeStartY, rangeEndX, rangeEndY]
    },
    // 标记范围的中心点
    markExtentCenter() {
      return getCenter(this.markExtent) || []
    },
    // 是否是线性标记
    isLineMaker() {
      return this.marker.type === 'LINE'
    },
    // 起点坐标(长度标记用)
    linemMarkerStartPoint() {
      return this.marker.pointList[0] || {}
    },
    // 终点坐标(长度标记用)
    linemMarkerEndPoint() {
      const pointList = this.marker.pointList
      return pointList[pointList.length - 1] || {}
    }
  },

  methods: {
    // 描述的绑定字段input事件处理
    editMarkerDesc(e) {
      this.$emit('updateDesc', e.target.value)
    },
    // 格式化数值(power: 幂方)
    formateMarkVal(val, power = 1) {
      const { scale } = this.marker
      return Number.parseInt(val * Math.pow(scale, power)) || '-'
    },
    getAreaByMeter(num) {
      return Math.round((num / 1000000 + Number.EPSILON) * 1000) / 1000
    },
    previewImage() {
      this.$viewerApi({
        images: [this.marker.imgUrl]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mark_item_wrapper {
  margin-bottom: 10px;
}

/* 卡片标题 */
.card_title {
  font-size: 1.2em;
  font-family: 'Microsoft YaHei';

  .coordinates {
    display: inline-block;
    margin-left: 2em;
    font-size: 0.8em;
  }
}

// 卡片内容
.main_data_content {
  padding: 10px;
  display: flex;

  // 左中右区块的总体样式
  .content_item {
    display: flex;
    flex-direction: column;

    .content_item_title {
      font-size: 1.1em;
      margin-bottom: 5px;
    }
  }

  /* 左侧图片区域样式 */
  .left_img {
    width: 200px;

    .img_item {
      width: 150px;
      height: 150px;
      object-fit: contain;
      cursor: pointer;
    }
  }

  // 中间坐标信息区域
  .center_coordinates_content {
    width: 240px;
    .item_title {
      display: block;
      margin: 5px 0;
    }
  }

  .right_desc {
    flex: 1;
  }
}
</style>
