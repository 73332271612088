var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "button_select_wrapper" },
    _vm._l(_vm.options, function (option, index) {
      return _c(
        "a-tag",
        {
          key: index,
          class: _vm.optionClass(option),
          attrs: { color: _vm.getType(option) },
          on: {
            click: function () {
              return _vm.handleSelectedChange(option.value)
            },
          },
        },
        [_vm._v(_vm._s(option.label))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }