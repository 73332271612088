<!--
 * @Description: 文件描述
-->
<script>
import ImgViewBtns from './ImgViewBtns.vue'
import MarkerCard from './MarkerCard.vue'
import ApiController from '../../api/functionApi'
import WallCotent from './WallCotent.vue'
import _ from 'lodash'
import $ from 'jquery'

const DEFAULT_TYPE_OPTIONS = [
  { label: '可见光', value: 'VISIBLE_LIGHT' },
  { label: '热成像', value: 'THERMAL_IMAGE' },
  { label: '敲击', value: 'KNOCK_IMAGE' },
  { label: '雷达', value: 'RADAR_IMAGE' }
]

export default {
  components: {
    ImgViewBtns,
    MarkerCard,
    WallCotent
  },
  props: {
    reportId: {
      type: Number,
      default: undefined
    },
    currentNode: {
      type: Object,
      default: () => ({})
    },
    buildingId: {
      type: Number,
      default: undefined
    },
    buildingScore: {
      type: String,
      default: undefined
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      building: {},
      defaultActiveKey: []
    }
  },
  watch: {
    buildingId(newVal) {
      if (newVal) {
        this.fetchData()
      }
    },
    async currentNode() {
      await this.$nextTick()

      if (this.currentNode.type !== 'WALL') {
        $('.building_wrapper > .ant-card-body')[0].scrollTo(0, 0)
        return
      }

      const scrollFunc = () => {
        const cardTop = $(`#wall_${this.currentNode.id}`).position()
        $('.building_wrapper > .ant-card-body')[0].scrollTo(0, cardTop.top)
      }

      if (this.fetchDataPromise) {
        await this.fetchDataPromise
        setTimeout(scrollFunc, 500)
      } else {
        scrollFunc()
      }
    }
  },
  methods: {
    fetchData() {
      const fetchFunc = async () => {
        this.building = {}
        const param = { reportId: this.reportId, buildingId: this.buildingId }
        try {
          this.loading = true
          const data = await ApiController.getReportBuildingInfo(param)
          this.building = data
          this.defaultActiveKey = data.wallList.map((item) => item.id)
        } catch (error) {
          console.log(error)
        } finally {
          this.loading = false
          this.fetchDataPromise = null
        }
      }

      this.fetchDataPromise = fetchFunc()
    },
    handleScoreChange(e) {
      this.$emit('scoreChange', e.target.value)
    },
    // 查看图片
    handleShowImg(type, wall) {
      // console.log(type, wall)
      const IMG_KEY_ENUM = {
        VISIBLE_LIGHT: 'visibleLight',
        THERMAL_IMAGE: 'thermalImage',
        KNOCK_IMAGE: 'knockImage',
        RADAR_IMAGE: 'radarImage'
      }

      const targetImg = wall[IMG_KEY_ENUM[type]]
      this.$viewerApi({
        images: [targetImg]
      })
    }
  },
  render() {
    const collapsePanelList = this.building.wallList?.map((wall) => {
      // 当前墙面拥有的图形列表
      const options = DEFAULT_TYPE_OPTIONS.filter((item) => {
        const AllOptions = _.uniq(wall.markerList.map((marker) => marker.dataType))
        return AllOptions.includes(item.value)
      })

      return (
        <a-collapse-panel key={String(wall.id)} header={'墙面 - ' + wall.number} id={`wall_${wall.id}`}>
          <ImgViewBtns
            options={options}
            onShowImg={(type) => this.handleShowImg(type, wall)}
            slot="extra"
          ></ImgViewBtns>
          <WallCotent markerList={wall.markerList} options={options}></WallCotent>
        </a-collapse-panel>
      )
    })

    return (
      <a-card class="building_wrapper">
        <div class="custom_card_title" slot="title">
          <span>{this.building.name}</span>
          <span style="display: inline-block; margin: 0 0.5em 0 2em;">专家评级：</span>
          <a-radio-group value={this.buildingScore} onChange={this.handleScoreChange} disabled={!this.editable}>
            <a-radio value={'A'}>A</a-radio>
            <a-radio value={'B'}>B</a-radio>
            <a-radio value={'C'}>C</a-radio>
            <a-radio value={'D'}>D</a-radio>
          </a-radio-group>
        </div>
        <a-spin spinning={this.loading}>
          <div style="min-height: 300px; width: 100%;">
            {this.building.wallList ? (
              <a-collapse expand-icon-position="right" defaultActiveKey={this.defaultActiveKey}>
                {collapsePanelList}
              </a-collapse>
            ) : null}
          </div>
        </a-spin>
      </a-card>
    )
  }
}
</script>

<style lang="scss" scoped>
.building_wrapper {
  height: 100%;
}
</style>
