var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base_info" },
    [
      _c("h2", { staticClass: "report_title" }, [_vm._t("default")], 2),
      _c("a-card", [
        _c("span", { attrs: { slot: "title" }, slot: "title" }, [
          _vm._v("项目名称：" + _vm._s(_vm.info.projectName)),
        ]),
      ]),
      _c("div", { staticClass: "mt-10" }),
      _c("a-card", { attrs: { title: "项目描述：" } }, [
        _c("p", [_vm._v(_vm._s(_vm.info.description))]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }