var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wall_btn_group" },
    [
      _c("span", [_vm._v("标记大图查看：")]),
      _vm._l(_vm.options, function (item) {
        return _c(
          "a-tag",
          {
            key: item.value,
            attrs: { color: "#003cff" },
            on: {
              click: function (e) {
                return _vm.handleShowImg(e, item.value)
              },
            },
          },
          [_vm._v(_vm._s(item.label))]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }